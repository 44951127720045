import React, {Component} from 'react';

import CaseStudy from '../../../components/CaseStudy'
import pdredesign from './_pdredesign'

import Mock1 from './mock_1.png';
import Mock2 from './mock_2.png';
import Mock3 from './mock_3.png';
import '../content.scss';

export default class Home extends Component {
  render() {
    return (
      <CaseStudy caseStudy={pdredesign}>
        <h1>Reinventing Professional Development for Teachers</h1>
        <p>PDredesign is a digital toolkit and community of practice dedicated to improving professional development
        for educators. The Readiness Assessment and other PDredesign tools were collaboratively designed with school
          districts who wanted to support their teachers through more relevant, flexible, timely and interactive
        professional development opportunities. These tools are freely available on PDredesign.org and can be used
        by any school district to transform existing professional development systems to better support teachers in
        the classroom.</p>
        <h1>Our process in building the tools</h1>
        <p>Mobility Labs user tested every step of the way. Consistently and unbiasedly collecting feedback
        before development sprints enabled us to create a tool that resonated with our audience.</p>
        <div className="casestudy-quote">
          <div className="quote-text">
          <blockquote className="blockquote">
            <p>The Readiness Assessment has been critical to improving our conversations about professional
            development.</p>
            <footer className="blockquote-footer">Bryan Joffe, <cite title="Source Title">AASA</cite></footer>
          </blockquote>
          <p>Understand how your district’s current PD system is structured and determine where to focus collective \
          energy for improvement.</p>
          </div>
          <div className="quote-image">
            <img src={Mock1} role="presentation" />
            <h2>The Readiness Assessment</h2>
          </div>
        </div>
        <div className="casestudy-quote">
          <div className="quote-text">
          <blockquote className="blockquote">
            <p>There is a high need for schools and districts to capture this information in one place.</p>
            <footer className="blockquote-footer">Amanda Smith, <cite title="Source Title">TNTP</cite></footer>
          </blockquote>
          <p>Get a complete picture of what data and technology systems are currently in use across departments.</p>
          </div>
          <div className="quote-image">
            <img src={Mock2} role="presentation" />
            <h2>Data and Tech Inventory</h2>
          </div>
        </div>
        <div className="casestudy-quote">
          <div className="quote-text">
          <blockquote className="blockquote">
            <p>We can now easily identify the tools no one uses and build upon the ones that are working.</p>
            <footer className="blockquote-footer">Margie Johnson, <cite title="Source Title">Metro Nashville Public
            Schools</cite></footer>
          </blockquote>
          <p>Perform a deep dive analysis into how data systems and technology support teacher professional
          development across several key areas.</p>
          </div>
          <div className="quote-image">
            <img src={Mock3} role="presentation" />
            <h2>Data and Tech Analysis</h2>
          </div>
        </div>
        <h1>Results of pdredesign</h1>
        <p>Teachers from across the nation have used the Readiness Assessment, the Data and Tech Inventory, and the
        Data and Tech Analysis to gain insight across their districts’ tools to inform pd decisions.</p>
      </CaseStudy>
    )
  }
}
